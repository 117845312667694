.content-with-sidebar {
    blockquote {
        width: 20em;
        float: left;
        margin-right: 2.5em;

        @include breakpoint(XS) {
            margin-right: 0;
            width: 100%;
        }
    }
}

.next-post {
    .cta.full-width {
        margin: 0;

        p {
            font-weight: bold;
        }
        .button {
            position: static;
            transform: none;
        }
        h2 {
            margin-bottom: 2rem;
        }
    }
}

.back {
    position: relative;
    color: $color-body;

    svg {
        position: absolute;
        left: -1.75em;
        top: 50%;
        transform: translateY(-50%);
        @extend %transition;
    }
    &:hover {
        svg {
            transform: translate(-0.25em, -50%);
        }
    }
}

.back-p {
    @include breakpoint(S) {
        text-align: center;
    }
}

.inner-content {
    max-width: 45em;

    @include breakpoint(S) {
        max-width: 40em;
        margin: 0 auto;
    }
    > p:first-child {
        font-size: 1.25rem;
        margin-bottom: 2em;
    }
}
.booking-mask {
    padding-left: 1.5em;
    text-align: left;
    color: #fff;
    border-left: 1px solid #fff;
    font-family: $font-stack-headings;

    @include breakpoint(S) {
        background: none;
        border: none;
        padding: 0;
        text-align: center;
        border-radius: 0;
        box-shadow: none;
    }
    .booking-form {
        @include breakpoint(S) {
            display: none;
        }
        .button {
            border-radius: 0;
            min-width: 0;
            width: 100%;
            border: 0;

            @include breakpoint(ML) {
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }
    .dropdown {
        margin: 0;
        border: 0;
        background: none;
        width: auto;
    }
    .input-wrap {
        position: relative;

        &.no-border {
            border: 0;
            margin-right: 0;
            padding-right: 0;
        }
        label {
            font-size: 0.75em;
            top: 0.5em;
            color: #fff;
        }
    }
    input {
        border: 0;
        margin: 0;
        line-height: 1.5;
        color: #fff;
        padding: 1.5em 0 0.5em 0;

        @include placeholder {
            color: #fff;
            font-family: $font-stack-headings;
            opacity: 0.7;
        }
        &:focus {
            border: 0;
        }
    }
    .rooms-guests-display {
        padding: 1.5em 0 0.5em 0;
        line-height: 1.5;
    }
    .rooms-guests-select {
        @extend %transition;
        position: absolute;
        bottom: calc(100% + 0.25em);
        opacity: 0;
        visibility: hidden;
        padding-bottom: 1em;
        z-index: 5;
        color: $color-body;

        .rooms-guests-select-inner {
            box-shadow: 0 0 4px 2px rgba(2,7,34,0.15);
            background: #fff;
            border-radius: 0.5em;
            padding: 1em;
        }
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7px 0 7px;
            border-color: #ffffff transparent transparent transparent;
            bottom: calc(1em - 6px);
            left: 1em;
        }
        &.active {
            bottom: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
    .rooms-guests {
        position: relative;
        cursor: pointer;
        z-index: 11;
        min-width: 10em;

        label {
            cursor: pointer;
        }
    }
    .rooms-guests-close {
        display: block;
        text-align: right;
        font-weight: bold;
        font-size: 0.75em;
    }
    .dates-display {
        display: flex;
        align-items: center;
        line-height: 1.5;
        padding: 1.5em 0 0.5em 0;

        .divider {
            padding: 0 0.5em;
            display: flex;
            align-items: center;
        }
    }
    p {
        margin: 0;
        line-height: 1;
    }
    .dates-wrapper {
        position: relative;
        min-width: 15em;

        @include breakpoint(M) {
            min-width: 12em;
        }
        .datepicker-trigger {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
        }
        > svg {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;

            @include breakpoint(M) {
                display: none;
            }
        }
    }
    .location-select {
        position: relative;
        min-width: 12em;

        .location-display {
            cursor: pointer;
            line-height: 1.5;
            padding: 1.5em 0 0.5em 0;
            display: block;
            position: relative;
            z-index: 1;
            min-width: 12em;
        }
        .location-selector {
            @extend %transition;
            position: absolute;
            bottom: calc(100% + 0.25em);
            opacity: 0;
            width: 100%;
            visibility: hidden;
            padding-bottom: 1em;
            z-index: 11;

            .location-selector-inner {
                box-shadow: 0 0 4px 2px rgba(2,7,34,0.15);
                background: #fff;
                border-radius: 0.5em;
                max-height: 12em;
                overflow: auto;
            }
            a {
                padding: 0.5em 1em;
                display: block;
                color: $color-body;

                &:hover {
                    color: #fff;
                    background: $color-body;
                }
            }
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 7px;
                border-color: transparent transparent transparent #fff;
                top: 0.5em;
                right: -6px;
            }
            &.active {
                top: 0.5em;
                right: calc(100% + 1em);
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .mobile-booking-trigger {
        display: none;
        max-width: 15em;
        margin: 0 auto;
        box-shadow: 0 0 4px 2px rgba(2,7,34,0.15);
    }
    @include breakpoint(S) {
        .mobile-booking-trigger {
            display: block;
        }
    }
}

.selector-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    justify-content: space-between;

    p {
        margin-right: 1em;
        font-weight: bold;

        span {
            font-weight: normal;
            font-size: 0.75em;
        }
    }
}

.selector {
    display: flex;
    align-items: center;

    button {
        min-width: 0;
        padding: 0;
        width: 1.75em;
        height: 1.75em;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0;
        border: 1px solid $color-primary;

        &:hover {
            border: 1px solid $color-secondary;
        }
        @include breakpoint(XS) {
            width: 2em;
            height: 2em;
        }
        &.disabled {
            opacity: 0.25;
            cursor: auto;

            &:hover {
                border: 1px solid $color-primary;
            }
        }
    }
    .selector-value {
        width: 2em;
        text-align: center;
    }
}

.mobile-booking-mask {
    display: none;
    @extend %transition;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    text-align: left;
    padding: 5em 1.5em 1.5em 1.5em;
    visibility: hidden;
    z-index: 100000;
    color: #fff;
    background: rgba($color-body, 0.9);
    overflow: auto;

    @include breakpoint(S) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    form {
        width: 100%;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .mobile-booking-mask-close {
        position: absolute;
        top: 2.75em;
        right: 1em;
    }
    input {
        color: #fff;
        border-bottom: 1px solid #fff;
        padding: 0.5em 0;

        @include placeholder {
            color: #fff;
            opacity: 1;
        }
    }
    p {
        font-weight: bold;
        margin: 0;
        color: #fff;
        font-size: 0.75em;
    }
    .input-wrap {
        &.submit {
            text-align: center;
            padding-top: 2em;
        }
    }
    .dropdown {
        padding-left: 0;

        select {
            padding-left: 0;
        }
        &:after {
            transform: rotate(180deg);
        }
    }
    .mobile-dates {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        margin-bottom: 1em;

        input {
            border: 0;
            margin: 0 0.5em 0 0;
            padding: 0.5em 0;
            background-image: url(/wp-content/themes/upcore_vogue/images/calendar-white.svg);
            background-size: 1.5em 1.5em;
            background-position: right center;
            background-repeat: no-repeat;
        }
        .divider {
            display: flex;
            align-items: center;
            padding: 0 1em;
        }
    }
    .rooms-guests-display {
        font-size: 16px;
        padding: 0.5em 0;
        border-bottom: 1px solid #fff;
        position: relative;
        @extend %transition;

        &.open {
            border-bottom: 1px solid $color-primary;

            svg {
                path {
                    fill: $color-primary;
                }
            }
        }
    }
    .mob-rooms-guests {
        margin-bottom: 1em;
        position: relative;

        p {
            @extend %transition;
        }
        &.open {
            > p {
                color: $color-primary;
            }
        }
        svg {
            position: absolute;
            right: 0.75em;
            width: 1em;
            height: 1em;
            top: calc(50% - 0.75em);
            transform: rotate(180deg);

            path {
                @extend %transition;
            }
        }
    }
    .rooms-guests-select {
        display: none;
        background: #fff;
        color: $color-body;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        padding: 1em;

        p {
            color: $color-body;
            font-size: 1em;
            line-height: 1;
        }
    }
}
// Colors
$color-body: #190505;
$color-primary: #B11917;
$color-primary-light: #F8F6F1;
$color-secondary: #190505;
$color-tertiary: #F8F6F1;
$color-smoke: #190505;

$color-active: #253796;
$color-success: #21ff86;
$color-error: #FF2133;

// Fonts
$font-stack-primary: "Nunito Sans", system-ui, sans-serif;
$font-stack-headings: "Cormorant", 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, serif;

$font-stack-system: system-ui, sans-serif;
$font-stack-monospace: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

// General Breakpoints
$breakpoints: (
  'XS': ( max-width:  40em ),
  'S': ( max-width:  48em ),
  'S-up': ( min-width: 48em ),
  'M': ( max-width:  64em ),
  'ML': ( max-width:  68em ),
  'L': ( max-width: 91em ),
  'XL': ( min-width: 91em ),
  'XXL': ( min-width: 105em ),
  'XXXL': ( min-width: 112em )
);

.theme--invert {
  color: #fff;
}

///////////////////////////
/// Nunito Sans ///
///////////////////////////

@font-face {
  font-family: 'Nunito Sans';
  src: url('/wp-content/themes/old_waverly_place/assets/fonts/nunito-sans/Nunito_Sans-latin.woff2') format('woff2');
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('/wp-content/themes/old_waverly_place/assets/fonts/nunito-sans/Nunito_Sans-latin-italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
}


///////////////////////////
/// End Nunito Sans ///
///////////////////////////


///////////////////////////
/// Cormorant ///
///////////////////////////

@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url('/wp-content/themes/old_waverly_place/assets/fonts/cormorant/Cormorant.woff2') format('woff2');
}

//System fallback fonts

@font-face {
  font-family: 'Iowan Old Style';
  src: local('Iowan Old Style');
  size-adjust: 87%; //Size relative to main font
  ascent-override: 106%; //Adjust the vertical position relative to main font
}

@font-face {
  font-family: 'Palatino Linotype';
  src: local('Palatino Linotype');
  size-adjust: 88%; //Size relative to main font
  ascent-override: 102%; //Adjust the vertical position relative to main font
}

@font-face {
  font-family: 'URW Palladio L';
  src: local('URW Palladio L');
  size-adjust: 88.125%; //Size relative to main font
  ascent-override: 100%; //Adjust the vertical position relative to main font
}

@font-face {
  font-family: 'P052';
  src: local('P052');
  size-adjust: 88.125%; //Size relative to main font
  ascent-override: 100%; //Adjust the vertical position relative to main font
}

///////////////////////////
/// End Cormorant ///
///////////////////////////
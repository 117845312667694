footer {
    text-align: center;
    //padding-top: 3em;
    padding-bottom: 1em;
    background: $color-body;
    color: #fff;

    @include breakpoint(S) {
        padding-bottom: 3em;
    }
    > .container {
        padding-top: 2.5rem;
    }
}
.footer-menu {
    text-align: left;
    flex-shrink: 0;
    font-weight: 300;

    @include breakpoint(S) {
        padding: 0;
        text-align: center;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            display: inline-block;
            padding: 0 0.75rem;
            border-right: 1px solid rgba(#fff, 0.5);

            &:last-child {
                border: 0;
            }
            &:before {
                display: none;
            }
            a {
                display: block;
                color: #8f8f8f;
                padding: 0;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.footer-social {
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        margin: 0 0.5em;
        color: #fff;

        &:hover {
            opacity: 0.6;
        }
        svg {
            display: block;
            height: 1.7em;
        }
        .what-three-words-button {
            svg path {
                fill: $color-primary;
            }
        }
    }
}

.hotel-details {
    margin-bottom: 1rem;
    p {
        margin: 0;
    }
    a {
        color: #fff;
        font-weight: normal;
        &:hover {
            color: #8f8f8f;
        }
    }
    span {
        margin: 0.5em;
        display: inline-block;
    }
    .disclaimer {
        opacity: 0.5;
        font-size: 0.8rem;
    }
}
.extra-info{
    opacity: .5;
    font-size: .6rem;
    margin-bottom: 1rem;
}

.logo-caption {
    margin-top: 1em;
    color: #fff;
    font-weight: 300;
    b {
        font-weight: 800;
    }
}

.footer-logo {
    display: block;
    padding-top: 2em;
    margin-bottom: 1em;
    color: #fff;

    svg {
        display: block;
        margin: 0 auto;
        width: 220px;
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.75em;
    .footer-menu {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        ul {
            margin: 0 1rem;
        }
    }
    @include breakpoint(S) {
        display: block;

        margin-bottom: 2rem;
    }
}

.copyright {
    text-align: center;
    padding-right: 1em;
    float: left;

    @include breakpoint(S) {
        padding: 0;
        text-align: center;
        margin-bottom: 1em;
    }
    p {
        margin: 0;
        color: #8f8f8f;

        a {
            color: #8f8f8f;

            &:hover {
                color: #fff;
            }
        }
    }
    .divider {
        padding: 0 0.5em;
    }
}

#ouibounce-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  
    .custom-modal-close {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 12;
        cursor: pointer;
        padding: 0.5em;

        svg {
            width: 2em;
            height: 2em;
            display: block;
        }
    }
    .underlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color-body, 0.9);
        cursor: pointer;
        animation: fadein 0.5s;
    }
    .modal {
        z-index: 1;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        animation: fadein 0.3s;
        background: #fff;
        display: flex;
        text-align: center;
        
        @include breakpoint(XS) {
            width: 90vh;
            height: 90vw;
            display: block;
        }
    }
    .modal-title {
        margin-bottom: 0.5rem;
    }
    li {
        text-align: center;
        padding-left: 0;
        padding-bottom: 0.75em;
        border-bottom: 1px solid $color-tertiary;

        &:before {
            display: none;
        }
    }
    .modal-inner {
        padding: 2em;

        p:last-child {
            margin: 0;
        }
    }
    .modal-footer {
        margin-bottom: 1.5rem;

        p {
            cursor: pointer;
            display: inline;
            color: $color-body;
            border-bottom: 1px solid $color-body;
            font-size: 0.85em;

            &:hover {
                color: $color-primary;
            }
        }
    }
  }
  @keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  @keyframes popin {
    0% {
        transform: scale(0);
        opacity: 0;
    }
  
    85% {
        transform: scale(1.05);
        opacity: 1;
    }
  
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.mob-controls {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    overflow: hidden;
    background: #fff;
    @extend %transition;

    .group-select {
        width: 50%;
        padding: 0.5em 1em;

        &.stretch {
            flex-grow: 1;
        }
    }
    .dropdown {
        margin: 0;
    }
    .faqs-search {
        padding: 0.5em 1em 0.5em 0.5em;
        width: 50%;

        svg {
            left: 0.5em;
        }
        .input-wrap {
            input {
                margin: 0;
                border: 0;
            }
        }
    }
    @include breakpoint(XS) {
        display: flex;
    }
    &.focus {
        width: 160%;

        .dropdown {
            select,
            label {
                opacity: 0.25;
            }
        }
    }
    .back-to-top {
        border: 0;
        border-radius: 0;
        min-width: 0;
        padding: 0.75em 1em 0.75em 1.5em;
        display: flex;
        align-items: center;
    }
}

div.footer-divider {
    display: block;
    height: 0;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    margin: 2em 3em;
    margin-bottom: 0em;
}

// New Mautic Newsletter Form
.mauticform_wrapper{
    display: block;
    padding-top: 1em;
    justify-content: center;
    background-color: #f7f7f7;
    margin: 0em 3em;
    form{
        display: flex;
        flex-direction: column;
        .text {
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;
            color: $color-body;
            flex-shrink: 1;
            h3  { font-size: 1em; }
            p   { font-size: 0.8em; }
            h3,p {text-align: center; margin-bottom: 0.25em;}
        }
        .mauticform-innerform{
            margin: 2em 0;
            width: 100%;
            .mauticform-page-wrapper{
                display: flex;
                justify-content: center;
                gap: 1em;
                label{
                    display: none;
                }
                input {
                    padding: 1em 1em;
                    margin-top: -1px;
                    background: #fff;
                    border: none;
                    &::placeholder {
                        margin-left: 0.32em;
                        color: #000;
                    }
                    &:focus {
                        outline: 0;
                    }
                    
                }
            }
        }
    }
    @include breakpoint(S) {
        form{
            .mauticform-innerform{
                .mauticform-page-wrapper{
                    flex-direction: column;
                }
            }
        }
    }
}

.footer-newsletter-signup {
    display: flex;
    justify-content: center;
    background-color: #f7f7f7;
    margin: 0em 3em;

    .button {
        padding: 0.8rem 2rem;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        color: $color-body;
        flex-shrink: 1;
        h3  { font-size: 1em; }
        p   { font-size: 0.8em; }
        h3,p {text-align: start; margin-bottom: 0.25em;}
    }
    .newsletter-form {
        width: 50%;
        position: relative;
        margin: 2em 3em;
        max-width: 30em;
        flex-shrink: 1;
        .newsletter-signup {
            &_input {
                input[type=email] {
                    margin-top: -1px;
                    background: #fff;
                    border: none;
                    &::placeholder {
                        margin-left: 0.32em;
                        color: #000;
                    }
                    &:focus {
                        outline: 0;
                    }
                    
                }
            }
            &_submit {
                position: absolute;
                top: -1px;
                right: -2px;
                z-index: 2;
                max-width: 33em;
                button {
                    border: 1px solid $color-primary;
                    &:hover {
                        border: 1px solid $color-body;
                    }
                }
            }
        }
    }
    @include breakpoint(M) {
        flex-direction: column;
        align-items: center;
        .text {
            margin-top: 2em;
            h3 {font-size: 1.2em;}
            p {font-size: 1em;}
            h3, p {text-align: center!important;}
        }
        div.newsletter-form {
            width: 100%!important;
        }
        .newsletter-signup {
            &_input {
                input[type=email] {
                    font-size: 1em;
                    margin-left: -30px;  
                }
            }
            &_submit {
                position: absolute;
                top: -1px;
                right: -2px;
                z-index: 2;
                max-width: 33em;
                margin-right: -30px;
                button {
                    border: none;
                }
            }
        }
    }
}
.extra-footer-text {
    opacity: .5;
}

.part-of {
    margin-bottom: 0.5rem !important;

    a {
        font-weight: bold;
    }
}
#bookingprocess {
    .GslBooking {
        .picker_header {
            font-family: "Roboto Slab","Didot","Bodoni MT","Georgia",serif;
        }
        .gslfront_1 {
                i {
                    border-radius: 4px;
                    background-color: #fff;
                    color: $color-primary;
                    border: 1px solid $color-primary;
                    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2)
        
                }
            }
            .gslbookingHeader {
                .gsl_button {
                    border: 1px solid $color-primary;
                    background: $color-primary;
                    color: #fff;
                    transition: all .5s ease-in-out;
                    &:hover {
                        background: #cfd3d3;
                        color: $color-primary;
                        transition: all .5s ease-in-out;
                    }
                }
                input {
                    color: inherit;
                    border: 0.5px $color-secondary solid;
                    width: 106%;
                }
            }
            .guestInfoBox {
                position: absolute;
                display: none;
                font-size: 16px;
                width: 100%;
                min-width: 300px;
                border-top: 2px solid $color-primary;
                padding: 16px;
                text-align: left;
                text-transform: capitalize;
                background-color: #fff;
                -webkit-box-shadow: none;
                box-shadow: none;
                z-index: 2;
                input {
                    border: 0;
                    width: 56%;
                }
        }
    }
}
#ui-datepicker-div {
    /*Calender Styling*/
    /*Normal Cell*/
    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        background-color: white;
        text-align: center;
    }
    /*Cell: today*/
    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
        border: 1px solid $color-primary;
        background: $color-primary-light;
        color: white;
    }
    /*Cell: Picked*/
    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
        border: 1px solid $color-primary;
        background: $color-primary;
        font-weight: normal;
        color: #fff;
    }
    .ui-state-hover {
        border-color: $color-primary-light;
        color: $color-primary-light;
    }
    /*Background reset*/
    .ui-widget-header {
        background: white;
    }
    table th {
        color: black;
        border: none;
        background: white;
    }
    table tr:nth-child(even) {
        background: none;
    }
}
/*booking calender and persons picker z-index
This is needed to set the z-index of the calnder/persons picker
as it hides behind the text without it*/
.home {
    #row-0 {
        position: relative;
        z-index: 2
    }
}
//

#bookingprocess .GslBooking .gslbookingHeader .gsl_button:hover {
    border: 1px solid #e168a7;
    background: #e168a7;
    color: #fff;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.offers {
  .card {
    .card-img {
      height: 100%;      
    }
    .card-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 2;
    }
  }
}
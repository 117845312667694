//Plugins
@import "susy";

//Variables, Functions, Mixins, etc
@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/animation-defs";
@import "utilities/animations";
@import "utilities/placeholders";

//styles particular to layout
@import "layout/elements";
@import "layout/page";
@import "layout/single";
@import "layout/footer";
@import "layout/gallery";
@import "layout/bookingengine";

// modules
@import "modules/builder-elements";
@import "modules/rooms-listing";
@import "modules/faqs";
@import "modules/blog";
@import "modules/offers";
@import "modules/instafeed";
@import "modules/booking-mask";

// Plugins
@import "utilities/fancybox";
@import "utilities/daterangepicker";
@import "utilities/slick";

// Print
@import "base/print";


//Requestd by client
.GslBooking .gslfront_1 .addguest {
background-color: transparent !important;
}
.GslBooking .gslfront_1 .addguest i {
border: solid 0px !important;
}
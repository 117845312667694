.builder-elements {
    #row-0{
        margin-bottom: 0;
    }
    .row {
        margin-bottom: 5rem;
        position: relative;
        &.no-margin {
            margin-bottom: 0;
        }
        .background-image-wrapper {
            padding: 2rem 0;
            color: #fff;

            &.first-element {
                margin-top: -5em;

                @include breakpoint(XS) {
                    margin-top: -2em;
                }
            }
        }
        img.background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
        div.background-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #000;
                opacity: 0.6;
            }
        }
    }
}

.callout-shortcode {
    background: $color-primary;
    color: #fff;
    padding: 1.2rem 1.5rem;
    margin-bottom: 1.4rem;
}

.full-width-cta {
    padding: 6rem 0;
    display: flex;
    align-items: center;
    position: relative;

    @include breakpoint(XS) {
        min-height: 26rem;
    }
   
    h2 {
        margin-top: 2rem;
    }
    .logo {
        width: 15rem;
        max-height: 6rem;
        margin: 0 auto;
        object-fit: contain;
    }
    .background-image{
        min-height: 27rem;
        &.no-after{
            &::after{
                display: none !important;
            }
        }
    }
}

.content-element {
    text-align: center;
    max-width: 50em;
    margin: 0 auto;
}
.column-content{
    display: grid;
    column-gap: 2rem;
    row-gap: 3rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    .content-lockup{
        width: 100%;
    }
    .column-content-content{
        margin-top: 1rem;
        text-align: center;
    }
}

.img-content {
    display: flex;

    @include breakpoint(XS) {
        flex-direction: column;
    }
    &.text-image {
        .content {
            order: 1;
            margin-left: 0;
            margin-right: 0.75em;
            padding-left: 0;
            padding-right: 2em;

            @include breakpoint(XS) {
                padding: 0;
                display: block;
                margin: 0;
                order: unset;
            }
        }
        .img {
            order: 2;
            margin-right: 0;
            margin-left: 0.75em;

            @include breakpoint(XS) {
                order: unset;
                width: auto;
                margin: 0 0 3em 0;
            }
        }
    }
    .content {
        width: calc(50% - 0.75em);
        margin-left: 0.75em;
        padding-left: 2em;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        align-items: center;

        @include breakpoint(XS) {
            padding: 0;
            margin: 0;
            width: 100%;
            flex-basis: auto;
        }
        h2 {
            margin-bottom: 0.5rem;
        }
        &.top-align {
            padding-top: 1em;
            padding-bottom: 7.5em;

            @include breakpoint(XS) {
                padding-bottom: 2em;
            }
        }
        .content-inner {
            width: 100%;
            .logo {
                margin: auto;
                margin-bottom: 2rem;
                width: 100%;
                height: 100%;
                max-height: 12rem;
                max-width: 18rem;
            }
            p {
                .button {
                    margin: 0.25em;
                }
                &:last-child {
                    margin: 0;
                }
            }
            .buttons {
                display: flex;
                align-items: flex-start;

                .button {
                    border: 1px $color-primary solid;
                    background: $color-primary;
                    color: #fff;

                    svg polyline {
                        @extend %transition;
                    }
                    &:hover {
                        background: #fff;
                        color: $color-body;
                        border: 1px #000 solid;
                        svg {
                            polyline {
                                stroke: #fff !important;
                            }
                        }
                    }
                }
            }
        }
        &.dark-content {
            color: $color-body;
            // background: #fff;
            padding-top: 3.75em;
            padding-bottom: 3.75em;
            padding-left: 3.75em;
            .content-inner {
                z-index: 1;
            }
            &::before {
                content: "";
                background: url(/wp-content/themes/old_waverly_place/images/sleep_mask.svg);
                background-size: contain;
                background-position-x: 12rem;
                background-position-y: -4rem;
                background-repeat: no-repeat;
                width: 30em;
                height: 30em;
                opacity: 1;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            .content-inner .red {
                color: $color-primary;
            }
            a {
                color: $color-body;
        
                &:hover {
                    opacity: 0.6;
                }
            }
            .contact-icons{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }
            .contact-icon-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: -1em;
                margin-bottom: 1em;
                svg {
                    fill: $color-primary;
                    color: $color-primary;
                    height: 1.25em;
                    margin-right: 0.25em;
                }
            }
            .buttons {
                .button {
                    border: 1px solid $color-primary;
                    &:nth-child(2n) {
                        border: 1px solid $color-body;
                        color: $color-body;
                        background: #fff;
                        &:hover {
                            color: #fff;
                            background: $color-primary;
                            border-color: $color-primary;
                        } 
                    }
                    &:hover {
                        opacity: 1;
                        border: 1px solid #000;
                        color: $color-body;
                    }
                }
            }
        }
    }
    .img {
        width: calc(50% - 0.75em);
        margin-right: 0.75em;

        img {
            height: 24em;
        }
        .tile-mask {
            img {
                height: 32em;
            }
        }
        .slick-prev,
        .slick-next {
            svg {
                polyline,
                line {
                    stroke: $color-primary !important;
                }
            }
        }
        .slick-prev {
            bottom: -3.25em;
            right: 6em;

            @include breakpoint(XS) {
                bottom: -3em;
                right: calc(50% + 2em);
            }
        }
        .slick-next {
            bottom: -3.25em;
            right: 0;

            @include breakpoint(XS) {
                bottom: -3em;
                right: auto;
                left: calc(50% + 2em);
            }
        }
        .carousel-pager {
            bottom: -3em;
            color: $color-body;
            right: 2.5em;

            @include breakpoint(S) {
                bottom: -2.5em;
            }
            @include breakpoint(XS) {
                right: calc(50% - 1.5em);
            }
        }
        @include breakpoint(XS) {
            width: auto;
            margin: 0 0 3em 0;

            img {
                height: 16em;
            }
        }
    }
    .intro {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba($color-body, 0.25);
        font-style: italic;
    }
}

.contact-block {
    padding-right: 0!important;

    .img {
        position: relative;
        margin: 0;
        width: calc(50% + 2.5em);

        @include breakpoint(XS) {
            width: 100%;
        }
        .map-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include breakpoint(XS) {
                position: relative;
                height: 25em;
            }
        }
    }
    .content {
        margin: 3.75em 0;
        padding-right: 2em;
        padding-left: 0;
        background-color: transparent;

        @include breakpoint(XS) {
            padding-left: 2em;
            margin: 0;
        }
        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     right: 100%;
        //     width: 5em;
        //     height: 100%;
        //     background: #fff;

        //     @include breakpoint(S) {
        //         width: 3em;
        //     }
        //     @include breakpoint(XS) {
        //         display: none;
        //     }
        // }
    }
    @include breakpoint(M) {
        padding-right: 2.5em;
    }
    @include breakpoint(S) {
        padding-right: 1.5em;
    }
    @include breakpoint(XS) {
        padding-right: 0;
    }
}

.icon-list {
    display: flex;
    flex-wrap: wrap;
    .slick-track {
        display: flex;
    }
    li {
        display: flex;
        margin: 0;
        padding: 1em 0 1em 1em;
        width: 50%;
        
        &.slick-slide {
            border-right: 2px solid rgba($color-smoke, 0.1);
            height: inherit;
            &::last-child {
                border: 0;
            }
            @include breakpoint (M) {
                border: 0;
            }
        }
    
        @include breakpoint(S) {
            width: 100%;
        }
        &:before {
            display: none;
        }
        .icon-img {
            margin-right: 2em;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            color: transparent;

            height: 5.7em;
            width: 5.7em;
            display: flex;
            z-index: -1;
            svg {
                line, polyline, polygon, circle, path, rect {
                    stroke: $color-primary;
                    stroke-width: 1px;
                }
            }
            svg,
            img {
                height: 3.5em;
                width: 3.5em;
                
                // @include breakpoint(XS) {
                //     height: 2.5em;
                //     width: 2.5em;
                // }
            }
        }
        h5 {
            margin: 0;
            font-weight: 400;
        }
        p {
            font-size: 0.875em;
        }
    }
    &.carousel {
        overflow: hidden;
        display: block;
        padding-left: 5em;
        padding-right: 5em;
    
        @include breakpoint(XS) {
            padding-left: 0;
            padding-right: 0;
        }
        li {
            padding: 2em 1em;
    
            @include breakpoint(XS) {
                border-right: 0;
            }
            &:last-child {
                border: 0;
            }
            .icon-img {
                float: none;
                margin: 0 auto 1em auto;
    
                @include breakpoint(S) {
                    margin: 0 auto 2em auto;
                }
            }
            p,
            svg {
                margin: 0;
            }
            .icon-content {
                text-align: center;
                h5 {
                    font-size: 1em;
                    padding-top: 1em;
                }
            }
        }
        .slick-arrow {
            padding: 0;
        }
    }
}

.controls-img-content-carousel {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
    a {
        color: #fff;
    }
}
.img-content-carousel-wrapper {
    position: relative;
}

.tile-mask {
    img {
        mask: url(/wp-content/themes/old_waverly_place/images/tile_mask.svg);
        mask-size: contian;
        mask-position: center;
        mask-repeat: no-repeat;
    }
}

.icon-carousel-content {
    padding-bottom: 1em;

    h2 {
        margin-bottom: 0;
    }
}

.map-container {
    height: 25em;
}

.instagram-container:last-child {
    // margin: 0;
    margin-bottom: 1rem;
}
.img-content-carousel {
    .slick-dots {
        margin-left: 40%;
    }
}

.img-content-slide {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .img-content-inner {
        height: auto;
        padding: 5em;
        width: 50%;
        background: rgba(0,0,0,0.75);
        color: #fff;
        position: relative;
        z-index: 3;

        @include breakpoint(S) {
            width: 60%;
        }
        @include breakpoint(XS) {
            width: 100%;
            min-height: 0;
            padding: 3.75em 1em;
        }
        h2 {
            margin: 0;
        }
    }
    .buttons {
        padding: 2em 0;
    }
    p:last-child {
        margin: 0;
    }
    .controls {
        display: none;
        align-items: flex-start;
        a {
            border-top: 1px solid #fff;
            display: block;

            &:hover {
                background: $color-primary;
            }
            svg {
                display: block;
            }
        }
        .prev-slide {
            border-right: 1px solid #fff;
        }
    }
}

.GslBooking .gslfront_1 .gsl_button {
    background-color:#e168a7 !important;
}
.GslBooking .gslfront_1 .addons_overview, .GslBooking .gslfront_1 .overview {
background-color:#9e969b !important;
}
.GslBooking .gslfront_1 .roomentry {
    border: 2px solid #e168a7 !important;
}

#bookingprocess .GslBooking .gslfront_1 .productentry_header, #bookingprocess .GslBooking .gslfront_1 .productentry_header div, #bookingprocess .GslBooking .gslfront_1 .roomheading, #bookingprocess .GslBooking .gslfront_1 .innertext {
    height: 36px !important;
}

.GslBooking .gslfront_1 .addons_overview, .GslBooking .gslfront_1 .overview {
box-shadow: hidden !important;
}

.GslBooking .invalidinput {
    border-color: #e168a7 !important;
    border: solid 1px #e168a7 !important;
}

.GslBooking .errormessage, .GslBooking .agreetotermserrormessage {
    color: #e168a7 !important;
}
.GslBooking .gslfront_1 .guestentry input {
    outline: none !important;
}

.GslBooking .overview_contact_tab {
    display: none !important;
}

.GslBooking .gslfront_1 .gsl_button.return {
    background-color: #e168a7 !important;
}

.background-image-wrapper .img-content .content .content-inner .buttons .button {
    background: #fff;
    border: 3px solid #fff;
    color: $color-primary;

    &:hover {
        background: $color-primary;
        color: #fff;
    }
}
.rel-posts {
    background: #fff;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;

    &.no-margin {
        margin-bottom: 0;
    }
    @include breakpoint(XS) {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .card {
        flex-grow: 1;

        @include breakpoint(XS) {
            margin: 1em;
        }
    }
    h2 {
        @include breakpoint(XS) {
            font-size: 1.5em;
            padding-right: 4em;
        }
    }
    .card-grid {
        @include breakpoint(XS) {
            margin-left: -1em;
            margin-right: -1em;
        }
    }
}

.cta-blocks {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint(S) {
        margin-left: -1.875em;
        margin-right: -1.875em;
    }
    &.cta-blocks-carousel {
        margin-left: -0.5em;
        margin-left: -0.5em;

        @include breakpoint(S) {
            margin-left: 0;
            margin-right: 0;
        }
        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                height: auto;
                min-height: 32rem;
                @include breakpoint(S) {
                    min-height: 45rem;
                }
            }
        }
        .slick-prev {
            left: -3.5em;
        }
        .slick-next {
            right: -3.5em;
        }
        .slick-arrow {
            padding: 0;
        }
        .cta {
            width: 100%;
            min-height: 25em;

            @include breakpoint(XS) {
                width: calc(100% - 1em);
                margin: 0 0.5em;
                min-height: 20em;
            }
        }
        .slick-track,
        .slick-list {
            width: 100%;
        }
    }
}

.cta {
    margin: 0.5em;
    min-height: 25em;
    width: calc(33.333% - 1em);
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    @include breakpoint(XS) {
        width: 100%;
        margin: 0.5em 0;
        min-height: 20em;
    }
    &.full-width {
        width: 100%;
        margin: 0.5em 0;
    }
    &.half {
        width: calc(50% - 1.01em);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.quarter {
        width: calc(25% - 1.01em);

        @include breakpoint(S) {
            width: calc(50% - 1.01em);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.short {
        height: 20em;

        .cta-inner {
            padding: 0;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @extend %nav-transition;
    }
    hr {
        border-top: 1px solid #fff;
    }
    &:before { 
        content: "";
        background: $color-body;
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        @extend %transition;
    }
    .cta-inner {
        position: relative;
        z-index: 3;
        color: #fff;
        text-align: center;
        width: 85%;
        padding: 1em 1em 3em 1em;

        @include breakpoint(XS) {
            width: 100% !important;
        }
    }
    h3 {
        max-width: 12em;
        margin: 0 auto;
    }
    p {
        max-width: 28em;
        margin: 0 auto 1.5rem auto;

        &.no-margin {
            margin: 0 auto;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include breakpoint(M) {
            max-width: 80%;
        }
        @include breakpoint(XS) {
            max-width: 100%;
            width: 100%;
        }
    }
    .date {
        font-size: 0.875em;
        margin-bottom: 1rem;
    }
    .cta-excerpt {
        margin-bottom: 1rem;

        p {
            margin: 0 auto;
            font-size: 0.75em;
        }
    }
    .date {
        margin-bottom: 0.5rem;
    }
    &.has-tagline {
        padding-top: 3em;

        @include breakpoint(XS) {
            min-height: 22em;
            padding-top: 1em;
        }
    }
    .tagline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 2.5rem;
        text-align: center;
        z-index: 3;
        color: #fff;

        &:before {
            content: "";
            position: absolute;
            width: 1px;
            left: 50%;
            height: 2em;
            background: #fff;
            top: 0;
        }
    }
    .button {
        min-width: 0;
        position: absolute;
        bottom: 1.5em;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);

        svg {
            display: block;
        }
    }
    &:hover {
        img {
            transform: scale(1.1) rotate(3deg);
        }
        &:before {
            opacity: 0.7;
        }
        .button {
            border: 1px solid $color-secondary;
            background: $color-secondary;
            color: $color-primary-light;
            &.transparent {
                border-color: $color-primary-light;
            }
        }
    }
    &.cta-content{
        @extend %transition;
        &::after,
        &::before{
            opacity: 0;
            @extend %transition;
        }
        .cta-inner{
            opacity: 0;
            @extend %transition;
        }
        &:hover{
            @extend %transition;
            &::after{
                display: none;
            }
            &::before{
                opacity: 0;
                @extend %transition;
            }
            .cta-inner{
                opacity: 0;
                @extend %transition;
            }
        }
    }
    &.cta-content-show{
        @extend %transition;
        &::after,
        &::before{
            opacity: 0;
            @extend %transition;
        }
        .cta-inner{
            opacity: 0;
            @extend %transition;
        }
        &:hover{
            @extend %transition;
            &::after{
                display: none;
            }
            &::before{
                opacity: 0.7;
                @extend %transition;
            }
            .cta-inner{
                opacity: 1;
                @extend %transition;
            }
        }
    }
}

.cta-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
    .cta {
        min-height: 25em;
        flex-grow: 0;

        .diamond {
            margin-bottom: 0;
        }
        .cta-inner {
            width: 100%;
        }
        &.home-tab-target {
            display: none;
            opacity: 0;

            &.active {
                display: flex;
                opacity: 1;
            }
            &.transparent {
                display: flex;
                opacity: 0;
            }
        }
    }
    &.feat-first {
        display: block;

        .cta,
        .card {
            float: left;
            height: 18em;
            min-height: 0;
            width: calc(25% - 1em);
            
            @include breakpoint(XS) {
                width: 100%;
            }
            &:first-child {
                width: calc(50% - 1em);
                height: calc(37em - 0.5px);

                @include breakpoint(XS) {
                    width: 100%;
                    height: auto;

                    h3 {
                        margin: 0;
                        font-size: 1.357em;
                        line-height: 1.289473684210526;
                    }
                }
            }
            .buttons {
                margin-bottom: 1rem;
            }
            .button {
                width: 10em;
                left: calc(50% - 5em);
                padding: 0.75em 1em;
                transform: none;
            }
        }
    }
}

.loadmore-wrapper {
    text-align: center;
    padding-top: 3em;
}

.blog {
    .posts-list .cta-grid .cta {
        .cta-inner {
            padding: 0.5em;

            @include breakpoint(XS) {
                padding: 0.5em 2em;
            }
        }
        h4 {
            line-height: 1.125;
            margin-bottom: 0.25em;
        }
        h6 {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;

            .divider:last-child {
                display: none;
            }
        }
        .button {
            padding: 0.5em;

            svg {
                width: 0.75em;
                height: 0.75em;
            }
        }
    }
}

.category-target.filtered {
    display: none;
}

.category-target.fade-out {
    opacity: 0;
}

.single-details {
    .divider:last-child {
        display: none;
    }
}

.share {
    padding-top: 1rem;
    
    .share-links {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 1.25em;
            height: 1.25em;

            path {
                @extend %transition;
            }
        }
        a {
            margin: 0 0.75em;

            &:hover {
                svg {
                    path {
                        fill: $color-body;
                    }
                }
            }
        }
    }
}

.posts-list {
    margin-bottom: 5em;
}

.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -0.5em;
    margin-right: -0.5em;

    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
}

.card {
    color: #fff;
    background: $color-body;
    margin: 0.5em;
    min-height: 25em;
    width: calc(33.333% - 1.01em);
    position: relative;
    text-align: center;
    
    @include breakpoint(S) {
        width: calc(50% - 1.01em);
    }
    @include breakpoint(XS) {
        width: 100%;
        margin: 0.5em 0;
        min-height: 20em;
    }
    &.half {
        width: calc(50% - 1.01em);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.quarter {
        width: calc(25% - 1.01em);

        @include breakpoint(S) {
            width: calc(50% - 1.01em);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &:hover{
        .card-img{
            img {
                transform: scale(1.1) rotate(3deg);
                @extend %nav-transition;
            }
            &:after {
                opacity: 0.7;
                @extend %nav-transition;
            }
        }
        .card-content{
            .buttons{
                .button{
                    border: 1px solid $color-secondary;
                    background: $color-secondary;
                    color: #fff;
                }
                .button.transparent {
                    background: $color-primary-light;
                    border: 1px solid $color-primary-light;
                    color: $color-body;
                }
            }
        }
    }
    .card-img {
        height: 18em;
        position: relative;
        display: block;
        overflow: hidden;
        @extend %nav-transition;
        &::after{
            content: "";
            background: #190505;
            opacity: .2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            @extend %nav-transition;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @extend %nav-transition;

            // &:hover {
            //     filter: grayscale(100%);
            // }
        }
    }
    .card-content {
        padding: 2.5em 2em 5em 2em;

        * {
            max-width: 100%;
        }
        .buttons {
            margin: 0;
            position: absolute;
            bottom: 1em;
            left: 0;
            width: 100%;
            
            a {
                position: static;
                margin: 0 0.5em;
            }
        }
    }
    .date {
        font-size: 0.875em;
        margin-bottom: 1rem;
    }
    h3 {
        margin-bottom: 0.5rem;
    }
    .card-mini-links {
        margin-bottom: 1.5rem;
        font-size: 0.875rem;

        p {
            display: inline;
        }
        a {
            font-weight: bold;
            color: #fff;

            &:hover {
                color: $color-primary;
            }
        }
    }
    h2,
    h3 {
        a {
            color: #fff;

            &:hover {
                opacity: 0.6;
            }
        }
    }
    .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2em;
    }
    .tagline {
        font-weight: bold;
        color: #fff;
    }
}

.feat-card {
    width: calc(100% - 1.01em);
    display: flex;

    .tagline,
    .card-mini-links,
    h2,
    h3 {
        margin-bottom: 1rem;
    }
    .card-img {
        height: auto;
        width: 50%;

        @include breakpoint(XS) {
            width: 100%;
            height: 18em;
        }
    }
    .tagline {
        color: $color-body;
    }
    h2 a,
    h3 a ,
    .card-mini-links a{
        color: $color-body;

        &:hover {
            color: $color-primary;
        }
    }
    .card-content {
        width: 50%;
        background: #fff;
        color: $color-body;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 2.5em 2em;

        @include breakpoint(XS) {
            width: 100%;
            display: block;
        }
    }
    .button {
        position: static;
        transform: none;
    }
    @include breakpoint(XS) {
        display: block;
        width: 100%;
    }
}

.archive {
    .banner-content-inner {
        p {
            margin-bottom: 0;
        }
    }
}
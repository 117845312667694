@media print {
    body, * {
        font-family: $font-stack-system !important;
    }

    header, .menu-wrapper, .button, .banner,
    footer {
        display: none !important;
    }

    img {
        display: block !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    html.cssanimations .fade-in, html.cssanimations .fade-in-up, html.cssanimations .fade-in-down, html.cssanimations .fade-in-left, html.cssanimations .fade-in-right {
        opacity: 1 !important;
    }
}
.page-content {
    max-width: 52em;
    margin-bottom: 5em;

    // li {
    //     padding-left: 0;
    //     border-bottom: 1px solid $color-tertiary;
    //     padding-bottom: 0.75em;

    //     &:before {
    //         display: none;
    //     }
    // }
}

.base-ctas {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5em;
    margin-left: -0.75em;
    margin-right: -0.75em;

    @include breakpoint(S) {
        flex-direction: column;

        .cta {
            width: calc(100% - 1.5em);
            min-height: 18em;
        }
    }
    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;

        .cta {
            width: 100%;
        }
    }
}

.content-with-sidebar {
    margin-bottom: 4rem;

    .sidebar {
        width: 20em;
        float: left;
    }
    .page-sidebar-content {
        width: calc(90% - 20em);
        float: right;

        @include breakpoint(S) {
            width: 100%;
        }
    }
}

.sidebar {
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba($color-body,0.25);

    .sidebar-widget {
        padding: 1.5em;
        border-bottom: 1px solid rgba($color-body,0.25);

        &:last-child {
            border-bottom: 0;
        }
        &.share {
            .share-links {
                margin: 0;
                justify-content: flex-start;

                a {
                    opacity: 0.5;

                    &:first-child {
                        margin-left: 0;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        &.newsletter {
            p {
                font-size: 0.875em;
            }
        }
    }
    @include breakpoint(S) {
        display: none;
    }
}

.padd-bottom {
    padding-bottom: 5em;
}

.page-template-page-wide {
    #chat-widget-container {
        bottom: 75px !important;
    }
}

#bookingprocess {
    table tr td {
        border: 0;
    }
    .GslBooking .gslfront_1 .productentry_header,
    .GslBooking .gslfront_1 .productentry_header div,
    .GslBooking .gslfront_1 .roomheading,
    .GslBooking .gslfront_1 .innertext {
        background: $color-primary;
    }
    .GslBooking .gslfront_1 .removeguest {
        background: $color-primary !important;
        z-index: 3;
    }
}

.product_availablerooms { display:none !important; }

.addonprinted .icon { display:none !important; }
.roomheading .guest_addon { display:none !important; }

.ibe-calendar {
    button svg polyline {
        stroke: #000 !important;
    }
}

.up-ibe {
    .ibe-form-control {
        font-family: $font-stack-primary !important;
    }
    .ibe-form-group.ibe-property-id {
        display: none;
    }
}

.page-id-863 {
    .instagram-feed {
        display: none;
    }
    .banner {
        min-height: 22em;
    }
}


.js-instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include breakpoint(L) {
        max-width: 55rem;
        margin: auto;
    }
}

.error404 {
    .banner-content-inner {
        display: none;
    }
}

.no-cssgrid {
    .full-width-cta {
        .content-element {
          .logo {
            width: 6rem;
            height: auto;
            max-height: none;
            min-height: none;
          }
        }
    }
    .what-three-words-button {
        svg {
            width: 2rem;
        }
    }
}

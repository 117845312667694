.instagram-feed {
  position: relative;
  text-align: center;
  .instagram_gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .slider-prev {
    svg {
      transform: rotate(-90deg);
    }
    @include breakpoint(S) {
      left: 0.25em;

      &:hover {
        transform: translateY(-50%);
      }
    }
  }
  .slider-next {
    svg {
      transform: rotate(90deg);
    }
    @include breakpoint(S) {
      right: 0.25em;

      &:hover {
        transform: translateY(-50%);
      }
    }
  }
  h2 {
    padding: 0 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5em;

    @include breakpoint(S) {
      font-size: 1rem;
      flex-direction: column;
    }
    svg {
      margin-right: 0.5rem;

      @include breakpoint(S) {
        margin: 0 0 0.5rem 0;
      }
    }
  }
  .button {
    margin-bottom: 2rem;
    padding-right: 4em;

    svg {
      position: absolute;
      right: 1.5em;
      top: 0.75em;

      @include breakpoint(S) {
        top: 0.5em;
      }
    }
    @include breakpoint(S) {
      min-width: 21em;
    }
  }
  .insta-slide {
    display: flex;
    align-items: center;
    position: relative;
    width: 14rem;
    height: 14rem;
    
    span.icon {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      // @include breakpoint(XS) {
      //   width: 250px;
      //   height: 250px;
      // }
    }
    .icon {
      position: absolute;
      bottom: 1rem;
      border-radius: 100%;
      width: 2.5rem;
      height: 2.5rem;
      left: calc(50% - 1.25rem);
      padding: 0.25em;
      z-index: 5;
      display: none;
      border-radius: 100%;
      background: $color-body;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      @include breakpoint(S) {
        flex: 1 1 50%;
        margin: 0;
      }
    }
    .overlay {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem;
      font-size: 0.75em;
      background: rgba($color-body, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 2;
      @extend %transition;
      
      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.instafeed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;

  @include breakpoint(S) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  h4 {
    font-size: 1.75em;
    margin: 0;
  }
  a {
    color: $color-body;
    font-size: 1.75em;
    font-family: $font-stack-headings;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.75em;

      path {
        fill: $color-body;
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
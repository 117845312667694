.gallery-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    @extend %transition;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 100;

        @include breakpoint(S) {
            display: block;
        }
    }
    .gallery-modal-images {
        width: calc(100% - 5em);
        position: relative;

        @include breakpoint(S) {
            width: 100%;
            height: calc(100% - 3.625em);
        }
        .button {
            position: absolute;
            z-index: 3;
            bottom: 1.75em;
            right: 2.625em;

            @include breakpoint(S) {
                left: 5em;
                right: 5em;
                bottom: 2em;
            }
        }
        .gallery-modal-slider,
        .slick-list,
        .slick-track,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .gallery-modal-counter {
        text-align: center;
        margin-bottom: 1em;

        @include breakpoint(S) {
            margin: 0 1em;
        }
    }
    .gallery-modal-slide {
        p {
            position: absolute;
            bottom: 1.75em;
            left: 2.625em;
            z-index: 3;
            margin: 0;
            color: #fff;

            @include breakpoint(S) {
                position: static;
                width: 100%;
                padding: 0 2em;
                text-align: center;
                margin-bottom: 1.5rem;
            }
        }
        @include breakpoint(S) {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            background: $color-body;
            justify-content: center;
            text-align: center;

            .button {
                position: static;
            }
            img {
                position: static;
                height: auto;
                margin-bottom: 2rem;
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#020722+0,020722+100&0+0,0.5+100 */
            background: -moz-linear-gradient(top, rgba(2,7,34,0) 0%, rgba(2,7,34,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00020722', endColorstr='#80020722',GradientType=0 ); /* IE6-9 */
        }
    }
    .gallery-modal-sidebar {
        width: 5em;
        position: relative;

        @include breakpoint(S) {
            width: 100%;
            height: 3.625em;
        }
    }
    .gallery-modal-close {
        position: absolute;
        top: 2em;
        right: calc(50% - 0.875em);
        @extend %transition;

        svg {
            display: block;
            width: 1.75em;
            height: 1.75em;
            line {stroke: $color-body;}
        }
        &:hover {
            transform: rotate(180deg)
        }
        @include breakpoint(S) {
            right: 2em;
            top: calc(50% - 0.875em);
        }
    }
    .gallery-modal-controls {
        position: absolute;
        width: 5em;
        right: 0;
        bottom: 0.5em;

        button {
            margin: 0 auto 0.5em auto;
            padding: 0.25em;
            display: block;

            svg {
                width: 1.25em;
                height: 1.25em;
            }
            @include breakpoint(S) {
                margin-bottom: 0;
                padding: 0 0.25em;

                &.gallery-modal-prev {
                    order: -1;
                }
            }
        }
        @include breakpoint(S) {
            right: auto;
            left: 2em;
            bottom: calc(50% - 0.875em);
            width: auto;
            display: flex;
            align-items: center;
        }
    }
}
@keyframes daterangepicker-down {
    0% {
      opacity: 0;
      transform: translateY(-0.25em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border: 0;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 3001;
    display: none;
    font-size: 15px;
    line-height: 1em;
    box-shadow: 0 0 4px 2px rgba(2,7,34,0.15);
    animation: daterangepicker-down 0.3s cubic-bezier(0,0,.13,.98);
    transform-origin: 50% 50%;
    animation-fill-mode: both;
    opacity: 1;
}

.daterangepicker table tr:nth-child(even) {
    background: none;
}

.daterangepicker:before, .daterangepicker:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
right: 9px;
}

.daterangepicker.opensleft:after {
right: 10px;
}

.daterangepicker.openscenter:before {
left: 0;
right: 0;
width: 0;
margin-left: auto;
margin-right: auto;
}

.daterangepicker.openscenter:after {
left: 0;
right: 0;
width: 0;
margin-left: auto;
margin-right: auto;
}

.daterangepicker.opensright:before {
left: 1em;
}

.daterangepicker.opensright:after {
left: 1em;
}

.daterangepicker.drop-up {
margin-top: -1em;
}

.daterangepicker.drop-up:before {
top: initial;
bottom: -7px;
border-bottom: initial;
border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
top: initial;
bottom: -6px;
border-bottom: initial;
border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
float: none;
}

.daterangepicker.single .drp-selected {
display: none;
}

.daterangepicker.show-calendar .drp-calendar {
display: block;
}

.daterangepicker.show-calendar .drp-buttons {
display: block;
}

.daterangepicker.auto-apply .drp-buttons {
display: none;
}

.daterangepicker .drp-calendar {
display: none;
}

.daterangepicker .drp-calendar.left {
padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
color: #fff;
border: solid black;
border-width: 0 2px 2px 0;
border-radius: 0;
display: inline-block;
padding: 3px;
}

.daterangepicker .calendar-table .next span {
transform: rotate(-45deg);
-webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
transform: rotate(135deg);
-webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
padding: 0.5em 1em;
background: none;
color: $color-body;
white-space: nowrap;
text-align: center;
vertical-align: middle;
min-width: 32px;
width: 32px;
height: 24px;
line-height: 24px;
font-size: 0.75em;
border-radius: 0;
border: 1px solid transparent;
white-space: nowrap;
cursor: pointer;
}

.daterangepicker .calendar-table thead tr:first-child th {
    padding-bottom: 0.5em;
}

.daterangepicker .calendar-table {
border: 1px solid #fff;
border-radius: 0;
background-color: #fff;
}

.daterangepicker .calendar-table table {
width: 100%;
margin: 0;
border-spacing: 0;
border-collapse: collapse;
}

.daterangepicker td.available:hover {
background-color: $color-body;
color: #fff;
border-color: transparent;
}

.daterangepicker th.available:hover {
    opacity: 0.5;
}

.daterangepicker td.week, .daterangepicker th.week {
font-size: 80%;
color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
background-color: #fff;
border-color: transparent;
color: #999;
}

.daterangepicker td.in-range {
background-color: rgba($color-body, 0.25);
border-color: transparent;
border-radius: 0;
}

.daterangepicker td.start-date {
border-radius: 2em 0 0 2em;
}

.daterangepicker td.end-date {
border-radius: 0 2em 2em 0;
}

.daterangepicker td.start-date.end-date {
border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
background-color: $color-body;
color: #fff;
border-color: transparent;
}

.daterangepicker th.month {
width: auto;
font-size: 1em;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
color: #999;
cursor: not-allowed;
text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
font-size: 12px;
padding: 1px;
height: auto;
margin: 0;
cursor: default;
}

.daterangepicker select.monthselect {
margin-right: 2%;
width: 56%;
}

.daterangepicker select.yearselect {
width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
width: 50px;
margin: 0 auto;
background: #eee;
border: 1px solid #eee;
padding: 2px;
outline: 0;
font-size: 12px;
}

.daterangepicker .calendar-time {
text-align: center;
margin: 4px auto 0 auto;
line-height: 30px;
position: relative;
}

.daterangepicker .calendar-time select.disabled {
color: #ccc;
cursor: not-allowed;
}

.daterangepicker .drp-buttons {
clear: both;
text-align: right;
padding: 8px;
border-top: 1px solid #ddd;
display: none;
line-height: 12px;
vertical-align: middle;
}

.daterangepicker .drp-selected {
display: inline-block;
font-size: 12px;
padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
margin-left: 8px;
font-size: 12px;
font-weight: bold;
padding: 4px 8px;
}

.daterangepicker.show-ranges .drp-calendar.left {
border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
float: none;
text-align: left;
margin: 0;
}

.daterangepicker.show-calendar .ranges {
margin-top: 8px;
}

.daterangepicker .ranges ul {
list-style: none;
margin: 0 auto;
padding: 0;
width: 100%;
}

.daterangepicker .ranges li {
font-size: 12px;
padding: 8px 12px;
cursor: pointer;
}

.daterangepicker .ranges li:hover {
background-color: #eee;
}

.daterangepicker .ranges li.active {
background-color: #08c;
color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
.daterangepicker {
    width: auto; }
    .daterangepicker .ranges ul {
    width: 140px; }
    .daterangepicker.single .ranges ul {
    width: 100%; }
    .daterangepicker.single .drp-calendar.left {
    clear: none; }
    .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .drp-calendar {
    float: left; }
    .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .drp-calendar {
    float: right; }
    .daterangepicker.ltr {
    direction: ltr;
    text-align: left; }
    .daterangepicker.ltr .drp-calendar.left {
        clear: left;
        margin-right: 0; }
        .daterangepicker.ltr .drp-calendar.left .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.ltr .drp-calendar.right {
        margin-left: 0; }
        .daterangepicker.ltr .drp-calendar.right .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.ltr .drp-calendar.left .calendar-table {
        padding-right: 8px; }
    .daterangepicker.ltr .ranges, .daterangepicker.ltr .drp-calendar {
        float: left; }
    .daterangepicker.rtl {
    direction: rtl;
    text-align: right; }
    .daterangepicker.rtl .drp-calendar.left {
        clear: right;
        margin-left: 0; }
        .daterangepicker.rtl .drp-calendar.left .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .daterangepicker.rtl .drp-calendar.right {
        margin-right: 0; }
        .daterangepicker.rtl .drp-calendar.right .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .daterangepicker.rtl .drp-calendar.left .calendar-table {
        padding-left: 12px; }
    .daterangepicker.rtl .ranges, .daterangepicker.rtl .drp-calendar {
        text-align: right;
        float: right; } }
@media (min-width: 730px) {
.daterangepicker .ranges {
    width: auto; }
.daterangepicker.ltr .ranges {
    float: left; }
.daterangepicker.rtl .ranges {
    float: right; }
.daterangepicker .drp-calendar.left {
    clear: none !important; } }
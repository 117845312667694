.faqs {
    .faqs-sidebar {
        float: left;
        width: 25em;
        padding-right: 7em;
        margin-top: 7em;
        z-index: 2;
        top: 7em;

        @include breakpoint(M) {
            width: 18em;
            padding-right: 1em;
        }
        @include breakpoint(XS) {
            display: none;
        }
    }
    .faqs-nav-wrapper {
        background: #efefef;
        margin-bottom: 3em;

        input {
            border: 0;
        }
    }
    .faq-nav {
        margin: 0;
        padding: 1.5em 2em;
        z-index: 2;
        position: relative;

        li {
            padding: 0;
            margin-bottom: 1em;

            &:before {
                display: none;
            }
            &:last-child {
                margin: 0;
            }
        }
        a {
            display: block;
            font-weight: bold;
            color: $color-body;
            opacity: 0.25;

            span {
                margin-right: 1em;
            }
            &:hover {
                opacity: 1;
            }
            &.active {
                color: $color-body;
                opacity: 1;
            }
        }
    }
    .faqs-content {
        float: right;
        margin-top: 7em;
        width: calc(100vw - 36em);
        padding-left: 1em;

        @include breakpoint(M) {
            width: calc(100vw - 26em);
        }
        @include breakpoint(XS) {
            width: 100%;
            margin-top: 3em;
            padding-left: 0;
        }
    }
}

.faqs-search {
    padding: 0.5em 2em 0 2em;
    position: relative;

    svg {
        position: absolute;
        left: 2em;
        top: 1.5em;
    }
    .input-wrap {
        margin-left: 2.5em;
    }
}

.faqs-notification {
    margin-bottom: 2em;
    display: none;

    @include breakpoint(XS) {
        text-align: center;
    }
    &.active {
        display: flex;
    }
    .results-for {
        padding: 1em;
        background: rgba($color-primary, 0.15);
        color: $color-primary;

        @include breakpoint(XS) {
            flex-grow: 1;
        }
    }
    .filter-count {
        padding: 1em;
        background: $color-primary;
        color: #fff;
        font-weight: bold;
    }
}

.no-results {
    display: none;
}
// End Activate grid/slides toggle //

.gallery {
  margin-top: 6em;
  margin-bottom: 4.125em;

  @include breakpoint(S) {
    margin-top: 5.5em;
  }
  &.cols {
    height: auto;
    column-count: 3;
    column-gap: 1.5em;

    @include breakpoint(S) {
      column-count: 2;
    }
    @include breakpoint(XS) {
      column-count: 1;
      display: block;
      column-count: auto;
    }
  }
  .grid-item {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 1.5em;

    &:after {
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @extend %transition;
    }
    &:hover {
      .grid-item-caption {
        opacity: 1;
      }
    }
    @include breakpoint(S) {
      width: 100%;
      margin-bottom: 1em;
    }
    figure {
      margin-bottom: 0;

      .grid-item-caption {
        @extend %transition;
        background: rgba($color-secondary, 0.75);
        text-align: center;
        color: #fff;
        opacity: 0;
        position: absolute;
        padding: 1em;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .caption-text {
        margin-bottom: 1rem;
        font-family: $font-stack-primary;
        font-size: 1.25em;
        position: relative;
        width: 100%;
        z-index: 1;
      }
      .expand-icon {
        position: relative;
        z-index: 1;
      }
    }
    &.category-target.fade-out {
        opacity: 0;
    }
    &.category-target.filtered {
      display: none;
    }
  }
  &.grid {
    margin-left: -0.5em;
    margin-right: -0.5em;
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      margin: 0.5em;
      height: 20em;
      width: calc(33% - 1em);

      @include breakpoint(S) {
        width: calc(50% - 1em);
      }
      @include breakpoint(XS) {
        width: calc(100% - 1em);
      }
      figure,
      img {
        height: 20em;
      }
    }
  }
}

.gallery-wrapper {
  margin-bottom: 3em;
}
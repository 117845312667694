.room-showcase {
    margin-bottom: 10em;
    display: flex;
    position: relative;
    width: calc(100% - 6em);

    .room-long-description {
        font-size: 0.875em;
        line-height: 1.25;
    }
  
    @include breakpoint(S) {
        width: calc(100% - 1.5em);
    }
    @include breakpoint(XS) {
        display: block;
        margin: 0 1em 1em 1em;
        width: calc(100% - 2em);
        background: #fff;
        box-shadow: -2px -2px 16px rgba(2, 7, 24, 0.1);
    }
    .room-img {
        width: 60%;
        min-height: 32em;
        position: relative;

        .gallery-modal-trigger {
            position: absolute;
            padding: 0.5em;
            bottom: 0;
            right: 0;
            z-index: 1;
            background: rgba($color-body, 0.5);

            &:hover {
                background: $color-secondary;
            }
            svg {
                display: block;
            }
            @include breakpoint(XS) {
                bottom: auto;
                top: 0;
                left: auto;
                right: 0;
            }
        }
        @include breakpoint(L) {
            min-height: 25em;
        }
        @include breakpoint(M) {
            min-height: 20em;
        }
        @include breakpoint(S) {
            width: 50%;
        }
        @include breakpoint(XS) {
            width: 100%;
            min-height: 15em;
        }
        .image-carousel,
        .slick-list,
        .slick-track,
        .image-carousel img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    p:last-child {
        margin: 0;
    }
    .room-details {
        position: relative;
        width: 40%;
        padding: 3.25em 4.5em;
        background: #fff;
        margin: 2.5em 0;
        z-index: 1;
  
        h2 {
            color: $color-body; 
            margin-bottom: 0.125em;
        }
        @include breakpoint(S) {
            width: 50%;
            padding: 3em;
        }
        @include breakpoint(XS) {
            width: 100%;
            padding: 5em 1.5em 2em 1.5em;
            transform: none;
            background: none;
            box-shadow: none;
        }
    }
    .from-price {
        position: absolute;
        top: 1em;
        right: 100%;
        background: #fff;
        text-align: center;
        width: 7.8125em;
        height: 7.8125em;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-family: $font-stack-headings;

        @include breakpoint(XS) {
            left: auto;
            right: 1em;
            top: 0;
            transform: translateY(-50%);
        }
        span {
            font-size: 0.75em;
            line-height: 1;
        }
        p {
            margin: 0;
            font-weight: bold;
            font-size: 2.5em;
            line-height: 1;
        }
    }
    .icon-list li {
        @include breakpoint(M) {
            width: calc(100% - 0.5em);
        }
        @include breakpoint(S) {
            width: calc(50% - 0.5em);
        }
    }
    .room-content {
        margin-bottom: 1em;
        display: none;

        @include breakpoint(XS) {
            display: none;
            padding-left: 1em;
            border-left: 1px solid $color-body;
        }
    }
    .room-content-toggle {
        display: inline-block;
        margin-bottom: 1em;
        font-weight: bold;
        color: rgba($color-body, 0.25);
        border-bottom: 2px solid $color-primary;
        color: $color-primary;
        margin-right: 2em;

        &::after {
            content: '-';
            margin-left: .5rem;
        }

        &.active {
            color: $color-body;
            &::after {
                content: '+';
                margin-left: .5rem;
            }
        }
        @include breakpoint(XS) {
            display: block;
        }
    }
    .virtual-tour-button {
        display: inline-block;
        margin-bottom: 1em;
        font-weight: bold;
        color: rgba($color-body, 0.25);
        // border-bottom: 2px solid $color-primary;
        color: $color-primary;

        svg {
            height: 1.65em;
            margin-left: 0.5em;
            position: absolute;
            top: -0.25em;
            path {
                stroke: $color-primary;
            }
        }

        &::after {
            content: '';
            margin-left: .5rem;
        }

        &.active {
            color: $color-body;
            &::after {
                content: '';
                margin-left: .5rem;
            }
        }
        @include breakpoint(XS) {
            display: block;
        }
    }
    &:nth-child(even) {
        margin-left: 6em;

        @include breakpoint(S) {
            margin-left: 1.5em;
        }
        @include breakpoint(XS) {
            margin: 0 1em 1em 1em;
            width: calc(100% - 2em);
        }
        .room-img {
            order: 2;
        }
        .room-details {
            order: 1;
        }
        .from-price {
            left: 100%;
            right: auto;

            @include breakpoint(XS) {
                left: auto;
                right: 1em;
                top: 0;
                transform: translateY(-50%);
            }
        }
    }
    &:nth-child(odd) {
        margin-right: 6em;
        
        @include breakpoint(S) {
            margin-right: 1.5em;
        }
        .title-line {
            h2 {
  
                &:after {
                    right: calc(100% + 0.25em);
                    left: auto;
                }
            }
        }
    }
    @include breakpoint(XS) {
        .slick-dots {
            text-align: left;
            width: auto;
            left: 1em;
        }
    }
}

.rooms-list.grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4.25em;
    margin-bottom: 5em;

    @include breakpoint(M) {
        padding: 0 1.75em;
    }
    @include breakpoint(S) {
        padding: 0 1em;
    }
    @include breakpoint(XS) {
        padding: 0;
    }
    .room-showcase {
        width: calc(50% - 1.5em);
        flex-direction: column;
        margin: 0.75em;
        background: #fff;

        @include breakpoint(XS) {
            width: calc(100% - 2em);
            margin: 0 1em 1em 1em;
        }
        &:nth-child(odd) {
            margin: 0.75em;

            @include breakpoint(XS) {
                margin: 0 1em 1.5em 1em;
            }
        }
        .room-img,
        .room-details {
            width: 100%;

            .gallery-modal-trigger {
                bottom: auto;
                left: auto;
                top: 0;
                right: 0;
            }
        }
        .from-price {
            left: auto;
            right: 2.5em;
            transform: translateY(-50%);

            @include breakpoint(S) {
                right: 1em;
            }
        }
        &:nth-child(even) .room-img {
            order: unset;
        }
        .room-details {
            padding: 2.5em;
            transform: none;
            box-shadow: none;

            @include breakpoint(M) {
                padding-top: 5em;
            }
            @include breakpoint(S) {
                padding: 5em 1.5em 2em 1.5em;
            }
            h2 {
                padding-right: 2em;

                @include breakpoint(M) {
                    padding-right: 0;
                }
            }
        }
        .icon-list li {
            width: calc(33% - 0.5em);

            @include breakpoint(M) {
                width: calc(50% - 0.5em);
            }
        }
        .slick-dots {
            bottom: 1em;
            left: 2em;
            text-align: left;
            width: auto;

            @include breakpoint(M) {
                bottom: -3em;
                z-index: 3;
            }
            @include breakpoint(S) {
                width: auto;
                left: 1em;
            }
        }
    }
}